import { getImatge, Imatge } from '@portal-internet/components';
import { getDurada, getHora, trimBR } from '@portal-internet/core';
import clsx from 'clsx';
import CodiEspectador from 'components/3cat/CodiEspectador';
import Compartir from 'components/3cat/Compartir';
import Player from 'components/3cat/Player';
import AfegeixALaLlista from 'components/3cat/ui/AfegeixALaLlista';
import Descarrega from 'components/3cat/ui/Descarrega';
import Icona from 'components/3cat/ui/Icona';
import LogoDirecte from 'components/3cat/ui/LogoDirecte';
import { useData } from 'hooks/useData';
import __isEmpty from 'lodash/isEmpty';
import { useEffect, useRef, useState } from 'react';
import styles from './itemaudio.module.scss';

const _getSubtitols = (props) => {
    let result, subtitols;
    const text = [];

    // català
    result = props.filter((subtitol) => subtitol.id === 'CAT_CA');
    if (!__isEmpty(result)) {
        text.push('català');
    }

    // castellà
    result = props.filter((subtitol) => subtitol.id === 'CAT_ES');
    if (!__isEmpty(result)) {
        text.push('castellano (traducción automática)');
    }

    // anglès
    result = props.filter((subtitol) => subtitol.id === 'CAT_EN');
    if (!__isEmpty(result)) {
        text.push('English (automatic translation)');
    }

    if (text.length > 0) {
        subtitols = 'Subtítols en: ' + text.toString().replaceAll(',', ' / ');
        return subtitols;
    }

    return false;
};

const _getAudioDescripcio = (props) => {
    let result = false;

    if (!__isEmpty(props)) {
        result = props.filter((audiodescripcio) => audiodescripcio.id === 'AUD');
    }
    return result;
};

const AudioDisponible = (props) => {
    const { state, endPubDate } = props;

    if (state === 'DESP' && endPubDate) {
        return (
            <div className={styles.noDisponible} data-testid='item-despublicat'>
                <div className={styles.msgNoDisponible}>
                    <Icona icona='avis' alt='avis' mida={28} />
                    <div className={styles.outertext}>Aquest àudio està despublicat</div>
                </div>
            </div>
        );
    }
    return <></>;
};

export default function Itemaudio(props) {
    const {
        autoPlay,
        canal,
        codiEspectador,
        directe,
        durada,
        despresTitol,
        despresHora,
        embed,
        id,
        titol,
        logo,
        parametresExtraWs,
        versio,
        subtitols,
        programa,
        variants,
        tipologia,
        dataPublicacio,
        tematica,
        state,
        endPubDate,
        tipus,
        mostraH1,
        isWebviewActive,
        isPopup,
        url_dades
    } = props;

    const entradeta = trimBR(props.entradeta);

    let { descarrega, arasona, despressona } = props;
    descarrega = descarrega !== false ? true : descarrega;
    const [triggerManualRefetch, setTriggerManualRefetch] = useState(false);
    const { getApiData } = useData();
    const [urlDades, setUrlDades] = useState(url_dades);
    const [offset, setOffset] = useState(0);
    const { data, refetch, dataUpdatedAt } = getApiData('POSTER_PETIT', urlDades);
    const botoDesplegable = useRef();
    const textDesplegable = useRef();
    const itemInfo = useRef();
    const itemAudio = useRef();
    const infoAudio = useRef(true);
    const [infoTitol, setInfoTitol] = useState(false);
    const [infoProgramaAra, setInfoProgramaAra] = useState(false);
    const [timeUpdate, setTimeUpdate] = useState(false);
    const [infoEntradeta, setInfoEntradeta] = useState(false);
    const [infoDespresTitol, setInfoDespresTitol] = useState(false);
    const [infoDespresSonaTitol, setInfoDespresSonaTitol] = useState(false);
    const [infoDespresHora, setInfoDespresHora] = useState(false);
    const [infoDespresSonaHora, setInfoDespresSonaHora] = useState(false);
    const [audioDesc, setAudioDesc] = useState(false);
    const [textAud, setTextAud] = useState('Activa');
    const [hideDurada, setHideDurada] = useState(false);
    const [isAdPlaying, setIsAdPlaying] = useState(false);
    const [infoHeight, setInfoHeight] = useState(0);
    const [intervalTrigger, setIntervalTrigger] = useState(null);
    const [mediaModel, setMediaModel] = useState(null);

    infoAudio.current = {
        end_time: props.end_time,
        autor: props.autor,
        artista: props.artista,
        fragment_obra: props.fragment_obra,
        titolarasona: props.titolarasona,
        poster: props.poster,
        presentador: props.presentador,
        isAraSona: arasona === 'SI' ? true : false,
        isDespresSona: despressona === 'SI' ? true : false,
        canalAraSona: id === 'ic' || id === 'cm'
    };

    const getInfoPrograma = () => {
        let res = '';

        if (!__isEmpty(infoAudio.current.autor) && infoAudio.current.isAraSona) {
            res = !__isEmpty(infoAudio.current.fragment_obra)
                ? infoAudio.current.autor + ' - ' + infoAudio.current.fragment_obra
                : infoAudio.current.autor;
        } else if (!__isEmpty(infoAudio.current.titolarasona) && infoAudio.current.isAraSona) {
            res = infoAudio.current.titolarasona;
            if (!__isEmpty(infoAudio.current.artista)) {
                res = res + ' - ' + infoAudio.current.artista;
            }
        } else if (!__isEmpty(infoAudio.current.presentador)) {
            res = 'amb ' + infoAudio.current.presentador;
        }
        return res;
    };

    const stylesInfo = clsx({
        [styles.info]: true
    });

    const stylesWrapper = clsx({
        [styles.itemAudioWrapper]: true,
        [styles.popup]: isPopup
    });

    const stylesItemaudio = clsx({
        [styles.itemaudio]: true,
        [styles.directe]: directe,
        [styles.ad]: isAdPlaying
    });

    const stylesWrapperEntradeta = clsx({
        [styles.wrapperEntradeta]: true,
        [styles.ad]: isAdPlaying
    });

    const stylesDurada = clsx({
        [styles.durada]: true,
        [styles.hide]: hideDurada
    });

    const stylesMobilesharing = clsx({
        [styles.mobileSharing]: true,
        [styles.sharing]: true,
        [styles.ad]: isAdPlaying
    });

    const stylesDataActualitzacio = clsx({
        [styles.slashVertical]: codiEspectador,
        [styles.dataActualitzacio]: true
    });

    const stylesEntradeta = clsx({
        [styles.slashVertical]: codiEspectador || (dataPublicacio && !['PUFIC_HUMOR', 'PUFICCIO'].includes(tematica))
    });

    const ocultaInfo = () => {
        setHideDurada(true);
    };

    const updateInfo = (dades) => {
        setInfoTitol(dades.titol);

        if (infoAudio.current.canalAraSona) {
            setOffset(dades.offset);
            setUrlDades(url_dades.replace('{{CADENA}}', id)?.concat(getDesplacamentArafem(dades.offset)));
            setTriggerManualRefetch(true);
        } else {
            setInfoTitol(dades.titol);
            setInfoEntradeta(trimBR(dades.entradeta));
            setInfoDespresTitol(dades.despresTitol);
            setInfoDespresHora(dades.despresHora);
            infoAudio.current.presentador = dades.presentador;
            $('.jw-preview').css('background-image', 'url(' + dades.imatgeKeyCrop + ')');
            $('.bitmovinplayer-poster').css('background-image', 'url(' + dades.imatgeKeyCrop + ')');
        }
        setInfoProgramaAra(getInfoPrograma());
    };

    const changeAudioDesc = () => {
        setAudioDesc(!audioDesc);
        let textAud = audioDesc ? 'Activa' : 'Desactiva';
        setTextAud(textAud);
    };

    const adPlaying = (ad) => {
        setIsAdPlaying(ad);
    };

    const _hasLogo = getImatge(logo, 'web_logo', null, null) ? true : false;

    const desplegaText = () => {
        let transitionDuration = (textDesplegable.current.scrollHeight - 72) / 240;
        textDesplegable.current.style.transitionDuration = transitionDuration + 's';
        textDesplegable.current.style.height = textDesplegable.current.scrollHeight + 'px';
        botoDesplegable.current.style.display = 'none';
        setTimeout(() => {
            textDesplegable.current.style.height = 'auto';
        }, transitionDuration * 1000);
    };

    const refreshArasona = (refreshTime) => {
        clearTimeout(intervalTrigger);
        setIntervalTrigger(null);
        setIntervalTrigger(
            setTimeout(() => {
                setUrlDades(url_dades.replace('{{CADENA}}', id)?.concat(getDesplacamentArafem(offset)));
                setTriggerManualRefetch(true);
            }, refreshTime)
        );
    };

    const getNextUpdate = (time, desplacament) => {
        let nextTimeUpdate = desplacament == 0 ? new Date(time) - new Date() : getRefreshOffsetTime(time, desplacament);
        nextTimeUpdate = Math.min(nextTimeUpdate, 300000);
        return nextTimeUpdate > 0 ? nextTimeUpdate : 20000;
    };

    const getDesplacamentArafem = (desplacament) => {
        let araFemDesplacament = '';
        if (desplacament && desplacament > 0) {
            let currentDateObj = new Date();
            let numberOfMlSeconds = currentDateObj.getTime();
            numberOfMlSeconds = numberOfMlSeconds - 60 * 60 * 1000;
            let addMlSeconds = -desplacament * 60 * 1000;
            let newDateOffset = new Date(numberOfMlSeconds + addMlSeconds);

            let mes = newDateOffset.getMonth() + 1;
            let any = newDateOffset.getFullYear();
            let dia = newDateOffset.getDate();
            let hora = newDateOffset.getHours();
            let minut = newDateOffset.getMinutes();
            if (mes < 10) {
                mes = '0' + mes;
            }
            if (dia < 10) {
                dia = '0' + dia;
            }
            if (minut < 10) {
                minut = '0' + minut;
            }
            if (hora < 10) {
                hora = '0' + hora;
            }
            araFemDesplacament = `&data=${any}${mes}${dia}${hora}${minut}`;
        }
        return araFemDesplacament;
    };

    const getRefreshOffsetTime = (startTime, offsetTime) => {
        let currentDateObj = new Date();
        let numberOfMlSeconds = currentDateObj.getTime();
        let addMlSeconds = offsetTime * 60 * 1000;
        let currentOffsetDate = numberOfMlSeconds - addMlSeconds;

        let dataStart = new Date(startTime);
        let dataStartMlSeconds = dataStart.getTime();
        return dataStartMlSeconds - currentOffsetDate;
    };

    const pushMediaModel = (mediaModel) => {
        setMediaModel(mediaModel);
    };

    useEffect(() => {
        if (url_dades) {
            setUrlDades(url_dades.replace('{{CADENA}}', id));
            setTriggerManualRefetch(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url_dades]);

    useEffect(() => {
        if (data) {
            let dataAraFem = _.get(data, 'canal.[0].ara_fem');
            let dataArasona = dataAraFem?.ara_sona;
            let dataDespressona = dataAraFem?.despres_sona;
            let nextUpdate = '';
            infoAudio.current.isAraSona = dataArasona?.ccmaarasona === 'SI';
            infoAudio.current.isDespresSona = dataDespressona?.ccmaarasona === 'SI';
            nextUpdate = getNextUpdate(dataArasona?.end_time, offset);

            if (infoAudio.current.isAraSona) {
                infoAudio.current.end_time = dataArasona?.end_time;
                infoAudio.current.autor = dataArasona?.autor;
                infoAudio.current.artista = dataArasona?.artista;
                infoAudio.current.fragment_obra = dataArasona?.fragment_obra;
                infoAudio.current.titolarasona = dataArasona?.titol;
                infoAudio.current.poster = dataArasona?.imatges[0]?.text;
                setInfoTitol(dataAraFem.titol_programa);
                setInfoProgramaAra(getInfoPrograma());
                setInfoDespresSonaHora(infoAudio.current.isDespresSona ? dataDespressona?.start_time : '');
                setInfoDespresSonaTitol(infoAudio.current.isDespresSona ? dataDespressona?.titol : '');
            } else {
                let dataDespresFem = _.get(data, 'canal.[0].despres_fem');
                infoAudio.current.autor = null;
                infoAudio.current.artista = null;
                infoAudio.current.fragment_obra = null;
                infoAudio.current.titolarasona = null;
                infoAudio.current.presentador = dataAraFem?.presentador;
                setInfoTitol(dataAraFem.titol_programa);
                setInfoProgramaAra(getInfoPrograma());
                setInfoDespresSonaHora(dataDespresFem?.start_time);
                setInfoDespresSonaTitol(dataDespresFem?.titol_programa);
                infoAudio.current.poster = getImatge(dataAraFem?.imatges, 'web_quadrat', null, '314x314');
            }
            if (infoAudio.current.poster) {
                $('.jw-preview').css('background-image', 'url(' + infoAudio.current.poster + ')');
                $('.bitmovinplayer-poster').css('background-image', 'url(' + infoAudio.current.poster + ')');
            }
            refreshArasona(nextUpdate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataUpdatedAt]);

    useEffect(() => {
        if (triggerManualRefetch && infoAudio.current.canalAraSona) {
            refetch();
            setTriggerManualRefetch(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerManualRefetch, refetch]);

    useEffect(() => {
        function calculaMes() {
            if (!__isEmpty(textDesplegable.current)) {
                if (textDesplegable.current.scrollHeight > textDesplegable.current.clientHeight + 5) {
                    botoDesplegable.current.style.display = 'block';
                } else {
                    botoDesplegable.current.style.display = 'none';
                }
            }
        }
        function calculaAlt() {
            if (!__isEmpty(itemInfo)) {
                setInfoHeight(itemInfo.current.scrollHeight);
            }
        }
        if (!directe) {
            calculaMes();
            window.addEventListener('resize', calculaMes);
        } else {
            calculaAlt();
            window.addEventListener('resize', calculaAlt);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //Modifiquem l'alçada perquè s'adapti a la mida del títol/entradeta de l'itemàudio en directe
        if (infoHeight > 0) {
            itemAudio.current.style.paddingTop = 'calc(100% / 840 * 437 + (' + infoHeight + 'px - 140px))';
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoHeight]);

    return (
        <article className={stylesWrapper}>
            <div className={stylesItemaudio}>
                <AudioDisponible state={state} endPubDate={endPubDate} />
                <div className={styles.audio} ref={itemAudio}>
                    <Player
                        id={id}
                        autoPlay={autoPlay}
                        directe={directe}
                        versio={versio}
                        parametresExtraWs={parametresExtraWs}
                        tipus={tipus}
                        className={styles.rmp}
                        ocultaInfo={ocultaInfo}
                        updateInfo={updateInfo}
                        adPlaying={adPlaying}
                        audioDesc={audioDesc}
                        textAud={textAud}
                        changeAudioDesc={changeAudioDesc}
                        isPopup={isPopup}
                        state={state}
                        endPubDate={endPubDate}
                        pushMediaModel={pushMediaModel}
                        variantsAD={variants}
                    ></Player>
                </div>
                <div className={stylesInfo} ref={itemInfo}>
                    {_hasLogo && (
                        <div className={styles.logoItemAudio}>
                            <Imatge
                                imatges={logo}
                                tipus='web_logo'
                                layout='fill'
                                objectFit='contain'
                                mostrarRecurs={false}
                                alt={programa}
                            />
                        </div>
                    )}
                    {!_hasLogo && !__isEmpty(programa) && <span className={styles.titolPrograma}>{programa}</span>}
                    {directe && !__isEmpty(canal) && (
                        <div className={styles.logoCanal}>
                            <Icona icona='directes' alt='Directe' className={styles.ico_directe} />{' '}
                            <LogoDirecte canal={canal} className={styles.logoDirecte} />
                        </div>
                    )}
                    <div className={styles.titolWrapper}>
                        {mostraH1 === true && <h1 className={styles.titol}>{infoTitol || titol}</h1>}
                        {mostraH1 === false && <h2 className={styles.titol}>{infoTitol || titol}</h2>}
                    </div>
                    {!directe && (
                        <div className={styles.sharing}>
                            <AfegeixALaLlista
                                className={styles.afegirLlista}
                                tooltipPosition='top'
                                id={id}
                                tipologia={tipologia}
                            />
                            {descarrega && (
                                <Descarrega className={styles.descarrega} tooltipPosition='top' mediaModel={mediaModel} />
                            )}
                            <Compartir
                                className='icona_compartir'
                                titol={infoTitol || titol}
                                mostraEmbed={true}
                                isWebviewActive={isWebviewActive}
                                tipus='audio'
                            />
                        </div>
                    )}
                    {directe && (
                        <>
                            <p className={styles.presentador}>{infoProgramaAra}</p>
                            {codiEspectador && (
                                <div className={styles.codiEdat}>
                                    <CodiEspectador codi={codiEspectador} alt={codiEspectador} className={styles.ico_codi} />
                                </div>
                            )}
                        </>
                    )}
                    <div className={styles.audioFooter}>
                        <div className={stylesDurada}>
                            {!__isEmpty(durada) && (
                                <>
                                    <Icona
                                        className={styles.ico_clock}
                                        icona='rellotge'
                                        isBlanc={true}
                                        mida='13'
                                        alt='Icona rellotge'
                                        ariaHidden={true}
                                    />
                                    <div>
                                        <span className='sr-only'>Durada: </span>
                                        {getDurada(String(durada))}
                                    </div>
                                </>
                            )}
                        </div>
                        {directe && (infoDespresTitol || despresTitol) && (
                            <div className={styles.continuacio}>
                                <Icona className={styles.ico_continuacio} alt='icona a continuació' icona='continuacio' /> A
                                continuació: {getHora(infoDespresSonaHora || infoDespresHora || despresHora)}{' '}
                                <span className={styles.despresTitol}>
                                    {infoDespresSonaTitol || infoDespresTitol || despresTitol}
                                </span>
                            </div>
                        )}
                    </div>

                    <div className={styles.botons}>
                        {_getAudioDescripcio(variants) && (
                            <>
                                <button className={styles.boto} aria-label='audiodescripció' onClick={changeAudioDesc}>
                                    <div className={styles.boto_text}>{textAud}</div>
                                    <CodiEspectador codi='AD' mida='11' estil='clickable' alt='Icona audiodescripció' />
                                </button>
                            </>
                        )}
                    </div>

                    {subtitols && (
                        <>
                            <div className={styles.subtitols}>
                                <CodiEspectador codi='Subtitols' alt='icona subtítols' mida='20' />
                                <p className={styles.txt_subtitols}>{_getSubtitols(subtitols)}</p>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {!directe && (
                <>
                    <div className={stylesWrapperEntradeta}>
                        <div className={styles.entradeta} ref={textDesplegable}>
                            {codiEspectador && (
                                <div className={styles.codiEdat}>
                                    <CodiEspectador
                                        codi={codiEspectador[0].id}
                                        alt={codiEspectador[0].desc}
                                        mida='20'
                                        className={styles.ico_codi}
                                    />
                                </div>
                            )}
                            {dataPublicacio && !['PUFIC_HUMOR', 'PUFICCIO'].includes(tematica) && (
                                <div className={stylesDataActualitzacio}>{dataPublicacio.split(' ').shift()}</div>
                            )}
                            <span
                                className={stylesEntradeta}
                                dangerouslySetInnerHTML={{ __html: infoEntradeta || entradeta }}
                            ></span>
                        </div>
                        <button className={styles.mes} ref={botoDesplegable}>
                            … <span onClick={() => desplegaText()}>Més</span>
                        </button>
                    </div>

                    <div className={stylesMobilesharing}>
                        <AfegeixALaLlista
                            className={styles.afegirLlista}
                            tooltipPosition='topright'
                            id={id}
                            tipologia={tipologia}
                        />
                        {descarrega && <Descarrega className={styles.descarrega} tooltipPosition='top' mediaModel={mediaModel} />}
                        <Compartir
                            className='icona_compartir'
                            titol={infoTitol || titol}
                            mostraEmbed={true}
                            isWebviewActive={isWebviewActive}
                            tipus='audio'
                        />
                    </div>
                </>
            )}
        </article>
    );
}

Itemaudio.defaultProps = {
    titol: '',
    programa: '',
    mostraH1: true,
    isWebviewActive: false,
    isPopup: false
};
