import { useEffect, useState } from 'react';
import Icona from 'components/3cat/ui/Icona/Icona';
import __isEmpty from 'lodash/isEmpty';
import __get from 'lodash/get';
import clsx from 'clsx';
import styles from './descarrega.module.scss';

const getHref = (url, slug) => {
    const lastIndex = url.lastIndexOf('.');
    return `${url.substr(0, lastIndex)}/${slug}${url.substr(lastIndex)}`;
};

function Descarrega(props) {
    const { tooltipPosition, className, media, mediaModel } = props;
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [mediaParams, setMediaParams] = useState(null);
    const [isTouched, setIsTouched] = useState(false);
    const textTooltip = media === 'audio' ? `Descarrega't l'àudio` : '';

    const stylesAfegeix = clsx({
        [styles.boto]: true,
        [styles.hover]: isTooltipVisible,
        [className]: !__isEmpty(className)
    });
    const stylesTooltip = clsx({
        [styles.tooltip]: true,
        [styles.tooltipfloat]: !isTouched,
        [styles.tooltiptouch]: isTouched,
        [styles[tooltipPosition]]: true
    });

    useEffect(() => {
        if (mediaModel) {
            const url = __get(mediaModel, 'attributes.media.models[0].attributes.url');
            const slug = __get(mediaModel, 'attributes.informacio.attributes.slug');
            if (url && slug) {
                setMediaParams({
                    href: getHref(url, slug),
                    url,
                    slug
                });
            }
        }
    }, [mediaModel]);

    return (
        !__isEmpty(mediaParams) && (
            <a
                target='_blank'
                rel='noreferrer'
                className={stylesAfegeix}
                aria-label={textTooltip}
                download={mediaParams.slug}
                href={mediaParams.href}
                onTouchStart={() => {
                    setIsTouched(true);
                }}
                onMouseEnter={() => {
                    setIsTooltipVisible(true);
                }}
                onMouseLeave={() => {
                    setIsTooltipVisible(false);
                }}
                data-testid='descarrega-media'
            >
                <Icona inline={true} icona='download_contorn_cercle' color='blanc' alt={textTooltip} />
                {isTooltipVisible && (
                    <div className={stylesTooltip} aria-hidden='true'>
                        {textTooltip}
                    </div>
                )}
            </a>
        )
    );
}

Descarrega.defaultProps = {
    tooltipPosition: 'left',
    media: 'audio'
};

export default Descarrega;
