import Destacat from 'components/3cat/Destacat';
import { ProducteContext } from 'context/3cat/ProducteProvider';
import { useContext } from 'react';
import Itemaudio from './Itemaudio';

export default function Component(props) {
    const { isWebviewActive } = useContext(ProducteContext);
    if (isWebviewActive) {
        return <Destacat {...props} tipus={'itemVideoWebview'} linkId={props.id} />;
    }
    return <Itemaudio {...props} />;
}
